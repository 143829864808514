[data-v-2cbcffcf] .el-textarea__inner {
  height: 70vh;
}
.dialog-footer[data-v-2cbcffcf] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}
[data-v-2cbcffcf] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item[data-v-2cbcffcf] {
  font-size: 24px;
  margin-left: 6px;
}
.iconLine[data-v-2cbcffcf] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
.borderLine[data-v-2cbcffcf] {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}