.custom-grid-layout[data-v-56d63ba9] {
  height: 100%;
}
.head-section[data-v-56d63ba9] {
  margin-bottom: 20px;
}
.iconLine[data-v-56d63ba9] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
[data-v-56d63ba9] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.searchInput[data-v-56d63ba9] {
  width: 300px;
  position: absolute;
  top: 9px;
  right: 255px;
}
.head-picker[data-v-56d63ba9] .el-date-editor.el-input {
  width: 20% !important;
}
[data-v-56d63ba9] .gridHeadLayout .el-button-group > .el-button:last-child {
  display: none !important;
}
.demo-ruleForm[data-v-56d63ba9] el-date-picker {
  width: 100% !important;
}